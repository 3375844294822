





















































































































import {
  Program,
  ProgramPlayer,
  ProgramPlayerEntity,
  DaysEnum,
  ProgramPlayerRequests,
  Transaction
} from "@sportango/backend";
import { CurrentUserMixin, EmitClose, LoadingMixin } from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import AddIcon from "@/assets/icons/saxcons/add-broken-white.svg";
import ProgramPlayerForm from "./ProgramPlayerForm.vue";
import { BaseUser } from "@sportango/backend";
import ProgramCapacityToggle from "@/components/programs/ProgramCapacityToggle.vue";
import AppDialog from "../Navigation/AppDialog.vue";
import ProgramPlayerItem from "./ProgramPlayerItem.vue";
import ProgramPlayerRequestItem from "@/components/programs/ProgramPlayerRequestItem.vue";
import { WatchLoading } from "@/decorators/Loading";
import NoData from "@/components/common/NoData.vue";

@Component({
  name: "program-players",
  components: {
    NoData,
    ProgramPlayerRequestItem,
    ProgramPlayerItem,
    AddIcon,
    ProgramPlayerForm,
    ProgramCapacityToggle,
    AppDialog
  }
})
export default class ProgramPlayers extends mixins(
  LoadingMixin,
  ResponsiveMixin,
  EmitClose,
  CurrentUserMixin
) {
  @Prop({ required: true })
  existingProgram!: Program;
  newPlayers: Array<ProgramPlayer> = [];
  isAdding = false;
  selectedDay: DaysEnum | "" | undefined = "";
  selectedPlayerType: "players" | "requests" = "players";

  get playerTypeOptions(): Array<{
    value: "players" | "requests";
    text: string;
  }> {
    return [
      {
        value: "players",
        text: `Players (${this.existingProgram.programPlayers?.length || 0})`
      },
      {
        value: "requests",
        text: `Requests (${this.existingProgram.playerRequests?.length || 0})`
      }
    ];
  }

  get players(): Array<ProgramPlayer> {
    let existingPlayers: Array<ProgramPlayer> = [];
    if (this.existingProgram?.programPlayers) {
      existingPlayers = this.existingProgram.programPlayers;
    }
    return [
      ...existingPlayers,
      ...this.newPlayers.map((n) => {
        return {
          ...n,
          isNew: true
        };
      })
    ];
  }

  get programTransaction(): Transaction | undefined {
    return this.$store.getters.transactions.find(
      (t) => t.parentItem === this.existingProgram.id
    );
  }

  get filteredPlayers(): Array<
    BaseUser & ProgramPlayer & { paymentStarted?: boolean }
  > {
    if (this.selectedDay === undefined || this.selectedDay === "") {
      return this.fullPlayers;
    }
    return this.fullPlayers.filter((f) => {
      return f.days?.includes(this.selectedDay as DaysEnum);
    });
  }

  get fullPlayers(): Array<
    BaseUser & ProgramPlayer & { paymentStarted?: boolean }
  > {
    const paidPlayers = this.programTransaction?.customers
      ?.filter((c) => !c.paidInCash)
      .map((c) => c.uid);
    return this.$store.getters.users
      .filter((f) => this.players.find((p) => p.uid === f.uid))
      .map((f) => {
        return {
          ...f,
          ...this.players.find((p) => p.uid === f.uid),
          paymentStarted: paidPlayers?.includes(f.uid)
        };
      })
      .sort((pl1, pl2) => Number(pl1.startDate) - Number(pl2.startDate));
  }

  get playerRequests(): Array<BaseUser & ProgramPlayerRequests> | null {
    if (this.existingProgram.playerRequests) {
      return this.$store.getters.users
        .filter((u) =>
          this.existingProgram.playerRequests?.find((p) => p.uid === u.uid)
        )
        .map((u) => {
          let playerRequest = this.existingProgram.playerRequests?.find(
            (p) => p.uid === u.uid
          );
          if (playerRequest !== undefined) {
            playerRequest = new ProgramPlayerEntity({
              uid: playerRequest?.uid,
              startDate: playerRequest?.startDate,
              days: playerRequest?.days,
              price: playerRequest.price
            });
          }
          return {
            ...u,
            ...playerRequest
          };
        })
        .sort((pR1, pR2) => Number(pR1.startDate) - Number(pR2.startDate));
    }
    return null;
  }

  get canEdit(): boolean {
    if (this.currentUser?.permissions) {
      return this.currentUser.permissions.hasAdminAccess;
    }
    return false;
  }

  get canAdd(): boolean {
    if (this.canEdit) {
      if (
        this.existingProgram.capacity &&
        this.existingProgram.days &&
        this.existingProgram.programPlayers
      ) {
        return this.usedCapacity < this.totalCapacity;
      }
      return true;
    }
    return false;
  }

  get pricesRequired(): boolean {
    if (this.existingProgram.prices && this.existingProgram.days) {
      if (
        this.existingProgram.prices.length === 0 ||
        this.existingProgram.prices.filter((p) => p.daysPrice === 0).length >
          0 ||
        this.existingProgram.prices.length < this.existingProgram.days.length
      ) {
        return true;
      }
      return false;
    }
    return true;
  }

  get totalCapacity(): number {
    if (this.existingProgram.capacity && this.existingProgram.days) {
      return this.existingProgram.capacity * this.existingProgram.days.length;
    }
    return 0;
  }

  get usedCapacity(): number {
    const playerDays = this.existingProgram.programPlayers?.map((p) => {
      if (p.days) {
        return p.days.length;
      }
      return 0;
    });
    return playerDays !== undefined && playerDays.length !== 0
      ? playerDays.reduce((o, n) => {
          return o + n;
        })
      : 0;
  }

  @WatchLoading()
  async addPlayer(player: ProgramPlayer): Promise<void> {
    this.isAdding = false;
    const payload: Program = {
      ...this.existingProgram,
      programPlayers: [...(this.existingProgram.programPlayers || []), player],
      playerRequests: this.existingProgram.playerRequests?.filter(
        (r) => r.uid !== player.uid
      )
    };
    await this.$store.dispatch("updateProgram", payload);
  }

  @WatchLoading()
  async mounted(): Promise<void> {
    if (this.existingProgram.programPlayers) {
      this.isLoading = true;
      await this.$store.dispatch(
        "getUsersById",
        this.existingProgram.programPlayers.map((p) => p.uid)
      );
      await this.$store.dispatch("getTransaction", this.existingProgram.id);
    }
  }
}
