





































import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import Vue from "vue";
import { SportangoSelect } from "@/components/Inputs/overrides";
import { ValidationRules } from "@/utils/validation";
@Component({
  name: "duration-slider",
  components: {
    SportangoSelect
  }
})
export default class DurationSlider extends Vue {
  @VModel({ required: true })
  dropdownSelection!: string | undefined;
  @Prop({ required: true, type: Array })
  items!: string[];
  @Prop({ required: false })
  rules!: ValidationRules;
  sliderValue = 0;
  get getTickLabels() {
    const items = this.items.slice(0, 4);
    items.push("More");
    return items;
  }
  get slidervalue() {
    return this.getTickLabels[this.sliderValue];
  }
  @Watch("dropdownSelection")
  updateSlider() {
    if (
      this.dropdownSelection &&
      this.getTickLabels.includes(this.dropdownSelection)
    ) {
      this.sliderValue = this.getTickLabels.indexOf(this.dropdownSelection);
    } else {
      this.sliderValue = this.getTickLabels.length - 1;
    }
  }
  @Watch("sliderValue")
  updateDropdown() {
    const item = this.getTickLabels.at(this.sliderValue);
    if (item && this.items.includes(item)) {
      this.dropdownSelection = item;
    }
  }
}
