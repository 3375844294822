




















































































































































































import Component, { mixins } from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import DollarIcon from "@/assets/icons/saxcons/dollar-circle-linear.svg";
import { Program, ProgramEntity } from "@sportango/backend";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { CourtsMixin, LoadingMixin } from "@/mixins/Helpers";
import DialogDatePicker from "@/components/Inputs/DialogDatePicker.vue";
import DialogTimePicker from "@/components/Inputs/DialogTimePicker.vue";
import {
  requiredFieldRule,
  numericPositiveIntegerRule,
  numericPositiveRule,
  ValidationRules
} from "@/utils/validation";
import MobileAutoComplete from "@/components/Navigation/MobileAutoComplete.vue";
import { AutoCompleteItem } from "@/components/Inputs/mixins";
import { findUsersForAutocomplete } from "@/store/actions/users";
import { SportangoSelect, SportangoTextField } from "../Inputs/overrides";
import DaysInput from "../Inputs/DaysInput.vue";
import UsersAutoComplete from "@/components/Navigation/UsersAutoComplete.vue";
import DurationSlider from "./DurationSlider.vue";
import { convert12HrFormatTo24Hr } from "@/utils/parser";
@Component({
  name: "program-details-form",
  components: {
    UsersAutoComplete,
    DaysInput,
    SportangoSelect,
    DollarIcon,
    MobileAutoComplete,
    DialogDatePicker,
    DialogTimePicker,
    SportangoTextField,
    DurationSlider
  }
})
export default class ProgramDetailsForm extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  CourtsMixin
) {
  @Prop({ required: false })
  private existingProgram: Program | undefined;
  @Ref("programForm")
  private programFormRef!: HTMLFormElement;
  private isEditMode: boolean;
  private isValid: boolean;
  private program: Program;
  private options = Program.options;
  private startTimePickerActive: boolean;
  private requiredFieldRule = requiredFieldRule;
  private numericPositiveIntegerRule = numericPositiveIntegerRule;
  private numericPositiveRule = numericPositiveRule;
  private endDateRules: ValidationRules;
  private get isReallyValid(): boolean {
    if (!this.program.days) {
      return false;
    }
    return this.isValid && this.program.days.length > 0;
  }
  private coachOptions: AutoCompleteItem[] = [];
  private validateEndDate(v: string) {
    if (this.program.startDate === undefined) {
      return "Please select Start Date First";
    }
    if (new Date(v) <= new Date(this.program.startDate)) {
      return "End Date must be after Start Date";
    }
    return true;
  }
  private validateStartTime(v: string) {
    const val = convert12HrFormatTo24Hr(v);
    if (this.program.startTime === undefined) {
      return "Please select a start time";
    } else if (Number(val.split(":")[0]) < 6) {
      return "Please a start time after 6 am";
    }
    return true;
  }
  constructor() {
    super();
    this.program = new ProgramEntity({});
    this.startTimePickerActive = false;
    this.isValid = false;
    this.isEditMode = false;
    this.endDateRules = [
      ...requiredFieldRule("End Date"),
      this.validateEndDate
    ];
  }

  get title(): string {
    if (this.existingProgram) {
      return `Change ${this.existingProgram.name}`;
    } else {
      return "Create new Program";
    }
  }
  private async submit(): Promise<void> {
    this.isLoading = true;
    const data = new ProgramEntity({
      ...this.program
    });
    if (this.isEditMode) {
      await this.$store.dispatch("updateProgram", data);
    } else {
      await this.$store.dispatch("addProgram", data);
    }
    this.$emit("next");
    this.isLoading = false;
  }
  async mounted(): Promise<void> {
    await this.searchCoaches("");
    if (this.existingProgram) {
      this.isEditMode = true;
      this.program = this.existingProgram;
      this.programFormRef.validate();
    }
  }
  private async searchCoaches(val: string) {
    this.coachOptions = await findUsersForAutocomplete(val, "COACH", 5);
  }
}
