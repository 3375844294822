










































import { Program, ProgramPricesEntity } from "@sportango/backend";
import { WORDS } from "@/utils/validation";
import { EmitClose, LoadingMixin } from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import dayjs from "dayjs";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { SportangoTextField } from "../Inputs/overrides";
import PriceIcon from "@/assets/icons/saxcons/dollar-circle-linear.svg";
import { WatchLoading } from "@/decorators/Loading";
import { ProrationMixin } from "@/mixins/Payments";

@Component({
  name: "program-pricing-form",
  components: {
    SportangoTextField,
    PriceIcon
  }
})
/** This module is used for updating prices associated with a program */
export default class ProgramPricingForm extends mixins(
  LoadingMixin,
  ResponsiveMixin,
  EmitClose,
  ProrationMixin
) {
  @Prop({ required: true })
  private existingProgram!: Program;
  private prices: Record<number, number> = {};
  private DAYS_WORDS = WORDS;
  constructor() {
    super();
  }
  get numberOfDays(): number {
    if (this.existingProgram.days) {
      return this.existingProgram.days.length;
    }
    return 7;
  }

  get btnText(): string {
    if (this.existingProgram?.prices) {
      return "Update";
    }
    return "Save";
  }

  get title(): string {
    return this.existingProgram.name + " Pricing - " + this.numberOfWeeks;
  }

  get numberOfWeeks(): string {
    const weeks = Math.ceil(
      dayjs(this.existingProgram.endDate).diff(
        this.existingProgram.startDate,
        "days"
      ) / 7
    );
    if (weeks === 1 || weeks === 0) {
      return "1 Week";
    } else {
      return `${weeks} Weeks`;
    }
  }

  @WatchLoading()
  async save(): Promise<void> {
    if (this.existingProgram.id) {
      const payload: Partial<Program> = {
        prices: Object.keys(this.prices).map(
          (n) =>
            new ProgramPricesEntity({
              timesPerWeek: Number(n),
              daysPrice: this.prices[Number(n)]
            })
        ),
        id: this.existingProgram.id
      };
      if (
        this.existingProgram.programPlayers &&
        this.existingProgram.programPlayers.length > 0
      ) {
        // updated price of every program
        payload.programPlayers = this.existingProgram.programPlayers.map(
          (p) => {
            return {
              ...p,
              price: this.calculateProgramPlayerProratePrice(p, {
                ...this.existingProgram,
                prices: payload.prices
              })
            };
          }
        );
      }
      await this.$store.dispatch("updateProgram", payload);
    }
    if (this.$route.query.returnTo === "players") {
      this.$router.replace(
        `/programs/view/${this.existingProgram.id}?mode=players`
      );
    } else {
      this.close();
    }
  }

  mounted() {
    const prices: Record<number, number> = {};
    if (this.existingProgram.prices) {
      this.existingProgram.prices.forEach((p) => {
        if (p.daysPrice && !isNaN(p.daysPrice)) {
          prices[p.timesPerWeek] = p.daysPrice;
        }
      });
    } else {
      for (let i = 1; i <= this.numberOfDays; i++) {
        if (this.prices[i]) {
          prices[i] = this.prices[i];
        } else {
          prices[i] = 0;
        }
      }
    }
    this.prices = prices;
  }
}
