















































































import Component, { mixins } from "vue-class-component";
import DollarIcon from "@/assets/icons/saxcons/dollar-circle-linear.svg";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { EmitClose, LoadingMixin } from "@/mixins/Helpers";
import { Program, ProgramPlayer } from "@sportango/backend";
import { Prop, Watch } from "vue-property-decorator";
import DialogDatePicker from "@/components/Inputs/DialogDatePicker.vue";
import { requiredFieldRule } from "@/utils/validation";
import UsersAutoComplete from "../Navigation/UsersAutoComplete.vue";
import ArrowIcon from "@/assets/icons/saxcons/arrow-right-linear.svg";
import { SportangoTextField } from "../Inputs/overrides";
import DaysInput from "../Inputs/DaysInput.vue";
import EditableInput from "@/components/Inputs/EditableInput.vue";
import { ProrationMixin } from "@/mixins/Payments";

@Component({
  name: "program-player-form",
  components: {
    EditableInput,
    DaysInput,
    SportangoTextField,
    ArrowIcon,
    UsersAutoComplete,
    DollarIcon,
    DialogDatePicker
  }
})
export default class ProgramPlayerForm extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  EmitClose,
  ProrationMixin
) {
  @Prop({ required: true })
  program!: Program;
  @Prop({ required: false, default: null })
  existingPlayer!: ProgramPlayer | null;
  @Prop({ required: false, default: false })
  isRequest!: boolean;
  @Prop({ required: false, default: "Add new Player" })
  title!: string;
  player: ProgramPlayer = {};
  isEditMode = false;
  isFormValid = false;
  readonly requiredFieldRule = requiredFieldRule;

  get price(): string {
    return this.calculatePlayerPriceFromProgram(
      this.program,
      this.player
    ).toString();
  }

  get proratePrice(): number {
    return this.calculateProgramPlayerProratePrice(
      this.player,
      this.program,
      this.price
    );
  }

  get playersToIgnore(): Array<string> {
    return this.program.programPlayers?.map((p) => p.uid || "") || [];
  }

  get slotsAvailable(): boolean {
    return (
      this.program.days?.map((d) => this.checkIfDayAllowed(d)).filter((b) => b)
        .length !== 0
    );
  }

  get playerSlotsAvailable(): boolean {
    return !(this.player.days || [])
      .map(
        (d) =>
          (this.program.programPlayers || []).filter((p) =>
            (p.days || []).includes(d)
          ).length < (this.program.capacity || 0)
      )
      .includes(false);
  }

  submit() {
    this.$emit("submit", {
      ...this.player,
      price: this.proratePrice
    });
    this.player = {};
  }

  mounted() {
    this.updatePlayer();
  }

  @Watch("existingPlayer")
  updatePlayer() {
    this.isEditMode = this.existingPlayer !== null;
    if (this.existingPlayer) {
      this.player = {
        ...this.existingPlayer
      };
    } else {
      this.player = {};
    }
  }

  checkIfDayAllowed(day: string): boolean | undefined {
    if (this.program.days) {
      if (!this.program.days.includes(day as Program.DaysEnum)) {
        return false;
      } else {
        // Calculate capacity and mark as empty
        if (this.program.programPlayers && this.program.capacity) {
          const capacityForDay = this.program.programPlayers.filter(
            (p) => p.days && p.days.includes(day as Program.DaysEnum)
          );
          return (
            this.program.capacity > capacityForDay.length ||
            this.player.days?.includes(day as Program.DaysEnum) ||
            this.program.programPlayers
              .find((p) => p.uid === this.player.uid)
              ?.days?.includes(day as Program.DaysEnum)
          );
        }
      }
    }
    return true;
  }
}
