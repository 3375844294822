




























import { Program, DaysEnum, ProgramPlayer } from "@sportango/backend";
import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";
import PlayersIcon from "@/assets/icons/saxcons/profile-2user-linear.svg";

@Component({
  name: "program-capacity-toggle",
  components: {
    PlayersIcon
  }
})
export default class ProgramCapacityToggle extends mixins(ResponsiveMixin) {
  @VModel({ type: String })
  private selectedDay!: DaysEnum | "";

  @Prop({ required: true })
  program!: Program;

  @Prop({ required: true })
  players!: Array<ProgramPlayer>;

  @Prop({ required: false })
  disabled!: boolean;

  private get days(): Array<{
    text: string;
    value: string;
    capacityPercentage: string;
    isFull: boolean;
  }> {
    if (!this.program.days) {
      return [];
    }
    return this.program.days.map((d) => {
      const dayCapacity = this.players.filter((p) =>
        p.days?.includes(d)
      ).length;
      return {
        text: this.isDesktop ? d : d[0],
        value: d,
        capacityPercentage: `${dayCapacity}/${this.program.capacity}`,
        isFull: dayCapacity === this.program.capacity
      };
    });
  }
}
