































import Component from "vue-class-component";
import { Mixins, Prop, VModel } from "vue-property-decorator";
import EditIcon from "@/assets/icons/saxcons/edit-2-linear.svg";
import TickIcon from "@/assets/icons/saxcons/tick-circle-linear.svg";
import { SportangoTextField } from "../Inputs/overrides";
import { TextFieldMixin } from "./mixins";
type TType = "number" | "text";

@Component({
  name: "editable-input",
  components: {
    SportangoTextField,
    EditIcon,
    TickIcon
  }
})
export default class EditableInput extends Mixins(TextFieldMixin) {
  isEditing = false;
  @VModel({ type: [String, Number] })
  newValue!: string | number;
  @Prop({ required: true, default: "", type: [String, Number] })
  oldValue!: string | number | null;
  @Prop({ required: false })
  type!: TType;

  get isValueDifferent(): boolean {
    if (isNaN(Number(this.newValue))) {
      return false;
    } else {
      return Number(this.newValue) !== Number(this.oldValue);
    }
  }
  get updatedNewValue() {
    return this.newValue;
  }
  set updatedNewValue(newValue) {
    if (this.type === "number") {
      this.newValue = Number(newValue);
    } else {
      this.newValue = newValue;
    }
  }

  setNewValue() {
    this.isEditing = false;
  }
}
