var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"program-players-container",attrs:{"loading":_vm.isLoading,"flat":"","width":_vm.isDesktop ? 500 : '100%'}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.existingProgram.name)+" ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex align-center w-100 flex-column gap-2"},[_c('program-capacity-toggle',{attrs:{"program":_vm.existingProgram,"players":_vm.fullPlayers},model:{value:(_vm.selectedDay),callback:function ($$v) {_vm.selectedDay=$$v},expression:"selectedDay"}}),_c('v-btn-toggle',{attrs:{"color":"primary"},model:{value:(_vm.selectedPlayerType),callback:function ($$v) {_vm.selectedPlayerType=$$v},expression:"selectedPlayerType"}},_vm._l((_vm.playerTypeOptions),function(playerType){return _c('v-btn',{key:playerType.value,attrs:{"value":playerType.value,"color":"primary","min-width":"150"}},[_vm._v(" "+_vm._s(playerType.text)+" ")])}),1),(_vm.pricesRequired)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.replace(
            ("/programs/view/" + (_vm.existingProgram.id) + "?mode=pricing&returnTo=players")
          )}}},[_vm._v(" Go to Pricing ")]):_vm._e()],1),_c('div',[(_vm.selectedPlayerType === 'players')?_c('div',{staticClass:"my-4 d-flex flex-column gap-1"},_vm._l((_vm.filteredPlayers),function(p){return _c('program-player-item',{key:p.uid,attrs:{"canEdit":_vm.canEdit,"player":p,"program":_vm.existingProgram}})}),1):_vm._e(),(
          _vm.selectedPlayerType === 'players' && _vm.filteredPlayers.length === 0
        )?_c('no-data',{attrs:{"width":350}}):_vm._e(),(
          _vm.selectedPlayerType === 'requests' &&
          _vm.playerRequests &&
          _vm.playerRequests.length > 0
        )?_c('div',{staticClass:"my-4 d-flex flex-column gap-1"},_vm._l((_vm.playerRequests),function(p){return _c('program-player-request-item',{key:p.uid,attrs:{"request":p,"program":_vm.existingProgram,"canEdit":_vm.canEdit}})}),1):_vm._e(),(
          _vm.selectedPlayerType === 'requests' &&
          (_vm.playerRequests === null || _vm.playerRequests.length === 0)
        )?_c('no-data',{attrs:{"width":350}}):_vm._e()],1),_c('app-dialog',{model:{value:(_vm.isAdding),callback:function ($$v) {_vm.isAdding=$$v},expression:"isAdding"}},[(_vm.isAdding)?_c('program-player-form',{attrs:{"program":_vm.existingProgram},on:{"submit":_vm.addPlayer,"close":function($event){_vm.isAdding = false}}}):_vm._e()],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.isLoading,"color":"error","text":""},on:{"click":_vm.close}},[_vm._v(" Close ")]),(_vm.canEdit)?_c('v-btn',{attrs:{"disabled":_vm.isLoading || _vm.pricesRequired,"rounded":"","color":"primary","loading":_vm.isLoading},on:{"click":function($event){_vm.isAdding = true}}},[_c('add-icon'),_vm._v(" Add ")],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }