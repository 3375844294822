






















































































import {
  BaseUser,
  Program,
  ProgramPlayerRequests,
  ProgramPlayerRequestsEntity
} from "@sportango/backend";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import AppDialog from "../Navigation/AppDialog.vue";
import TrashIcon from "@/assets/icons/saxcons/trash-linear.svg";
import EditIcon from "@/assets/icons/saxcons/edit-2-linear.svg";
import { ResponsiveMixin } from "@/mixins/Responsive";
import ProgramPlayerForm from "@/components/programs/ProgramPlayerForm.vue";
import TickIcon from "@/assets/icons/saxcons/tick-circle-linear.svg";
import { ProrationMixin } from "@/mixins/Payments";

@Component({
  name: "program-player-request-item",
  components: {
    ProgramPlayerForm,
    AppDialog,
    EditIcon,
    TickIcon,
    TrashIcon
  }
})
export default class ProgramPlayerRequestItem extends mixins(
  ResponsiveMixin,
  ProrationMixin
) {
  @Prop({ required: true })
  request!: BaseUser & ProgramPlayerRequests;

  @Prop({ required: true })
  canEdit!: boolean;

  @Prop({ required: true })
  program!: Program;

  isRemoving = false;
  isDeleting = false;
  isEditing = false;
  isUpdating = false;
  isAdding = false;
  isBeingAdded = false;

  async deleteRequest() {
    this.isDeleting = true;
    const payload: Program = {
      ...this.program,
      playerRequests: this.program.playerRequests?.filter(
        (p) => p.uid !== this.request.uid
      )
    };
    await this.$store.dispatch("updateProgram", payload);
    this.isDeleting = false;
    this.isRemoving = false;
  }

  async addPlayer() {
    this.isBeingAdded = true;
    const payload: Program = {
      ...this.program,
      programPlayers: [
        ...(this.program.programPlayers || []),
        {
          ...this.request,
          price:
            this.request.price ||
            this.calculateProgramPlayerProratePrice(
              this.request,
              this.program,
              this.program.prices?.find(
                (p) => p.timesPerWeek === this.request.days?.length
              )?.daysPrice || 0
            )
        }
      ],
      playerRequests: this.program.playerRequests?.filter(
        (p) => p.uid !== this.request.uid
      )
    };
    await this.$store.dispatch("updateProgram", payload);
    this.isBeingAdded = false;
    this.isAdding = false;
  }

  async updateRequest(updatedRequest: ProgramPlayerRequests) {
    this.isEditing = false;
    this.isUpdating = true;
    const payload: Program = {
      ...this.program,
      playerRequests: this.program.playerRequests?.map((p) => {
        if (p.uid === updatedRequest.uid) {
          return new ProgramPlayerRequestsEntity(updatedRequest);
        }
        return p;
      })
    };
    await this.$store.dispatch("updateProgram", payload);
    this.isUpdating = false;
  }

  get existingRequest(): ProgramPlayerRequests {
    return {
      uid: this.request.uid,
      startDate: this.request.startDate,
      days: this.request.days,
      price: this.request.price
    };
  }
}
