












































































import { BaseUser, Program, ProgramPlayer } from "@sportango/backend";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import AppDialog from "../Navigation/AppDialog.vue";
import TrashIcon from "@/assets/icons/saxcons/trash-linear.svg";
import EditIcon from "@/assets/icons/saxcons/edit-2-linear.svg";
import { ResponsiveMixin } from "@/mixins/Responsive";
import ProgramPlayerForm from "./ProgramPlayerForm.vue";
import dayjs from "dayjs";

@Component({
  name: "program-player-item",
  components: {
    ProgramPlayerForm,
    AppDialog,
    EditIcon,
    TrashIcon
  }
})
export default class ProgramPlayerItem extends mixins(ResponsiveMixin) {
  @Prop({ required: true })
  player!: BaseUser & ProgramPlayer & { paymentStarted?: boolean };

  @Prop({ required: true })
  canEdit!: boolean;

  @Prop({ required: true })
  program!: Program;

  isRemoving = false;
  isDeleting = false;
  isEditing = false;
  isUpdating = false;

  async deletePlayer() {
    this.isDeleting = true;
    const payload: Program = {
      ...this.program,
      programPlayers: this.program.programPlayers?.filter(
        (p) => p.uid !== this.player.uid
      )
    };
    await this.$store.dispatch("updateProgram", payload);
    this.isDeleting = false;
    this.isRemoving = false;
  }

  async updatePlayer(updatedPlayer: ProgramPlayer) {
    this.isEditing = false;
    this.isUpdating = true;
    const payload: Program = {
      ...this.program,
      programPlayers: this.program.programPlayers?.map((p) => {
        if (p.uid === updatedPlayer.uid) {
          return updatedPlayer;
        }
        return p;
      })
    };
    await this.$store.dispatch("updateProgram", payload);
    this.isUpdating = false;
  }

  get existingPlayer(): ProgramPlayer {
    return {
      uid: this.player.uid,
      startDate: this.player.startDate,
      price: this.player.price,
      days: this.player.days
    };
  }

  get startDate(): string {
    return dayjs(this.player.startDate).format("MM/DD/YYYY");
  }
}
